<template>
    <div :style="blockStyle">
        <div
                v-for="block in blocks"
                :key="block.guid"
                :style="`overflow:hidden;white-space:nowrap;position: absolute;transform: translate(${block.x}px, ${block.y}px); z-index: auto; width: ${block.width}px; height: ${block.height}px;`">
            <component :is="block.component" v-bind="block.properties" :data="data"></component>
        </div>
    </div>
</template>

<script>
import { components, loadComponents } from './components'
export default {
  name: 'design-viewer',
  components: loadComponents(components),
  props: {
    blockStyle: {
      type: String,
      default: null
    },
    blocks: {
      type: Array,
      default: () => {
        return []
      }
    },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  }
}
</script>

<style scoped>

</style>

<template>
    <design-viewer
            :block-style="resultStyle"
            :blocks="template"
            :data="data"
    ></design-viewer>
</template>

<script>
import DesignViewer from '@/components/InterfaceEditor/components/basic/HtmlInterfaceDesigner/viewer'
export default {
  name: 'list-footer',
  components: {
    DesignViewer
  },
  props: {
    template: {
      type: Array,
      default: () => {
        return []
      }
    },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    componentStyle: {
      type: String
    }
  },
  computed: {
    resultStyle () {
      return `z-index: 1;min-height: 48px;position: relative;${this.componentStyle}`
    }
  },
  data () {
    return {
      resultTemplate: `<div :style="resultStyle">${this.template}</div>`
    }
  }
}
</script>
